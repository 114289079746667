import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        fixedHeight: "579px",
        speed: 2000,
        lazyLoad: "sequential",
        pagination: false,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();
}

const about = document.getElementById("about");
if (about) {
    const aboutSplide = splide(about, {
        perPage: 1,
        breakpoints: {},
    });
    aboutSplide.mount();
}

const news = document.getElementById("news");
if (news) {
    const newsSplide = splide(news, {
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 2,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    newsSplide.mount();
}

const partners = document.getElementById("partners");
if (partners) {
    const partnerSplide = splide(partners, {
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
            },
        },
    });
    partnerSplide.mount();
}

const service = document.getElementById("service");
if (service) {
    const serviceSplide = splide(service, {
        perPage: 3,
        breakpoints: {
            1200: {
                perPage: 3,
                arrows: false,
                pagination: true,
            },
            600: {
                perPage: 1,
                arrows: true,
                pagination: true,
            },
        },
    });
    serviceSplide.mount();
}
